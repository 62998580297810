@import "../mixins/projects";
@import "../mixins/variables";
@import "../mixins/media";
/*
マイページ

マイページで利用するためのスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 9.1
*/

/*
マイページ

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist

Styleguide 9.1.1
*/
.ec-navlistRole{
  & &__navlist {
    @include reset_link;
    display: flex;
    flex-wrap: wrap;
    border-color: #D0D0D0;
    border-style: solid;
    border-width: 1px 0 0 1px;
    margin-bottom: 32px;
    padding: 0;
    list-style: none;
    @include media_desktop() {
      flex-wrap: nowrap;
      justify-content: space-between;
      border: none;
    }
  }

  & &__item{
    width: 50%;
    border-color: #D0D0D0;
    border-style: solid;
    border-width: 0 1px 1px 0;
    text-align: center;
    // font-weight: bold;
    
    @include media_desktop() {
      border: 1px solid $clrYellowishGreen;
      border-radius: 10px;
      overflow: hidden;

      &:not(:first-child) {
        margin-left: 10px;
      }
    }

    a {
      padding: 16px;
      width: 100%;
      height: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transition: .3s ease-in-out;
      &:hover{
        // background: #f5f7f8;
        background-color: $clrYellowishGreen;
        color: white;
      }
    }
  }
  .active {
    a {
      // color: #DE5D50;
      background-color: $clrYellowishGreen;
      color: white;
    }
  }
}

/*
マイページ（お気に入り機能無効）

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist_noFavorite

Styleguide 9.1.2
*/

/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

ex [マイページ　メニューリスト下部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-welcomeMsg

Styleguide 9.1.3
*/
.ec-welcomeMsg{
  @include mypageContainer;
  margin: 1em 0;
  padding-bottom: 32px;
  text-align: center;
  // @include borderBottom;

  @include media_desktop() {
    border-bottom: 3px solid $clrBg;
  }
}

/*
お気に入り一覧

お気に入り一覧で表示するアイテムの表示コンポーネントです。

ex [マイページ　お気に入り一覧](http://demo3.ec-cube.net/mypage/favorite)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-favorite

Styleguide 9.1.4
*/
.ec-favoriteRole{
  & &__header {
    margin-bottom: 16px;
  }
  & &__detail {
  }
  & &__itemList {
    @include reset_link;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
  }
  & &__item{
    margin-bottom: 8px;
    width: 47.5%;
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    &-image {
      height: 150px;
      margin-bottom: 10px;
      text-align: center;
      @include media_desktop() {
        height: 250px;
      }
    }
    img{
      width: auto;
      max-height: 100%;
    }
    @include media_desktop(){
      width: 25%;
    }
    .ec-closeBtn--circle {
      position: absolute;
      right: 10px;
      top: 10px;
      .ec-icon img{
        width: 1em;
        height: 1em;
      }
    }
  }
  & &__itemThumb {
    display: block;
    height:auto;
    margin-bottom: 8px;
  }
  & &__itemTitle{
    margin-bottom: 2px;
  }
  & &__itemPrice{
    font-weight: bold;
    margin-bottom: 0;
  }

}

/*
登録情報編集

登録情報編集で表示するアイテムの表示コンポーネントです。

ex [マイページ　登録情報編集](http://demo3.ec-cube.net/mypage/change)
*/
.ec-editRole {

  .ec-borderedDefs {
    border-top: none;
  }

}


/*
Epsilon 定期購入メニュー
*/
#gmoEpsilonRegularNavList {
  border-top-style: none;

  @include media_tablet {
    flex-wrap: nowrap;
  }
}
#gmoEpsilonRegularNavList > li {
  border-top: 1px solid #D0D0D0;

  @include media_tablet {
    width: auto;
    // min-width: 120px;
    flex: 1 0 auto;
    margin-top: 10px;

    a {
      padding: 12px;
    }
  }
}
