@import "../mixins/variables";
/*
アイコン

デフォルトテンプレートのアイコンは`.ec-icon`>`img`タグで使用することができます

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Markup:
include /assets/tmpl/elements/4.1.icon.pug
div(style="background-color: rgba(130,130,130,.15); padding: 20px;")
  +icon-all

Styleguide 4.1
*/
.ec-icon img {
  max-width: 80px;
  max-height: 80px;
}


@font-face {
  font-family: 'kashimura-icon';
  src:
    url('../fonts/kashimura-icon.ttf?nsrnms') format('truetype'),
    url('../fonts/kashimura-icon.woff?nsrnms') format('woff'),
    url('../fonts/kashimura-icon.svg?nsrnms#kashimura-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'kashimura-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-footer-arrow:before {
  content: "\e900";
}
.icon-arrow-up:before {
  content: "\e901";
}
.icon-arrow:before {
  content: "\e902";
}
.icon-cart:before {
  content: "\e903";
}
.icon-register:before {
  content: "\e904";
}
.icon-login:before {
  content: "\e905";
}
.icon-mypage:before {
  content: "\e906";
}
.icon-logout:before {
  content: "\e907";
}
.icon-menu:before {
  content: "\e908";
}
.icon-home:before {
  content: "\e909";
}
.icon-pagetop:before {
  content: "\e90a";
}
.icon-close:before {
  content: "\e90b";
}
