@import "../mixins/media";
@import "../mixins/clearfix";

/*
カテゴリ（アイテム4列 スマホの時は2列）

トップページで使用されているアイテムリストのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.6.1
*/

.ec-newItemRole {
  padding: 40px 0;

  @include media_desktop {
    padding: 60px 0;
  }

  & &__list {
    display: flex;
    flex-wrap: wrap;

    @include media_desktop {
      flex-wrap: nowrap;
    }

  }
  & &__listItem {
    margin-bottom: 4%;
    width: 48%;
    height: auto;

    &:not(:first-child){
      a {
        color: black;
      }
    }

    @include media_desktop {
      margin-bottom: 15px;
      width: calc(100% / 4);

      &:not(:last-of-type){
        margin-right: 30px;
      }
    }

    &:nth-child(odd){
      margin-right: 4%;

      @include media_desktop {
        margin-right: 30px;
      }
    }
  }
  & &__listItemHeading {
    margin-top: calc(45% - 20px);
  }
  & &__listItemTitle {
    margin: 8px 0;
    font-size: 14px;
    font-weight: bold;

    @include media_desktop {
      margin: 20px 0 10px;
    }
  }

  & &__listItemPrice {
    font-size: 12px;
  }

}


/*
Instagram記事表示

トップページで使用されているInstagramのスタイルです。

Styleguide 12.6.2
*/
.instagramRole {
  width: 100%;
  max-width: 498px;
  max-height: 530px;
  margin: 0 auto;
  overflow-y: scroll;

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    width: 50%;
    padding: 1px;
  }

  a {
    position: relative;
    display: block;
    padding-bottom: 100%;
    overflow: hidden;

    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // z-index: 1;
      // object-fit: cover;
      // transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    span.video {
      position: absolute;
      display: block;
      top: 8px;
      right: 8px;
      z-index: 2;
      &::before {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        background-image: url(../img/common/movie.svg);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    span.c {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      opacity: 0;
      transition: all 0.3s;

      span {
        display: inline-block;
        padding: 0 8px;
        max-width: 50%;
        color: #fff;
        font-size: 14px;
        text-align: center;
        line-height: 20px;
        vertical-align: middle;
        white-space: nowrap;

        &::before {
          content: "";
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 8px;
          background-size: cover;
          background-repeat: no-repeat;
          vertical-align: middle;
        }

        &.like {
          &::before {
            background-image: url(../img/common/like.svg);
          }
        }
        &.comment {
          &::before {
            background-image: url(../img/common/comment.svg);
            background-size: auto 18px;
          }
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0;
      transition: all 0.3s;
    }

    &:hover {
      span.c {
        opacity: 1;
      }

      &::before {
        opacity: 0.3;
      }
    }
  }

}