@import "../mixins/projects";
@import "../mixins/variables";
// @import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/buttons";
// @import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/tab-focus";
// @import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/opacity";
// @import "../../../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/vendor-prefixes";

$padding-base-vertical:     6px !default;


$btn-primary-bg: #97BC65;
$btn-primary-color: #fff;
$btn-action-bg: #EFA856;
$btn-action-color: #fff;
$btn-cancel-bg: #525263;
$btn-cancel-color: #fff;
$btn-default-bg: #EFA856;
$btn-default-color: #fff;

$btn-border: transparent;
$btn-border-radius-base: 35px;


// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 25%);
  }
  &:hover {
    color: $color;
    background-color: darken($background, 10%);
    border-color: darken($border, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
    background-image: none;
    border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
      border-color: darken($border, 25%);
    }
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}


@mixin _btn($color, $background, $border){
  display: inline-block;
  min-width: 186px;
  margin-bottom: 0; // For input.btn
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  // border: 1px solid transparent;
  border: none;
  border-radius: 35px;
  white-space: nowrap;
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base);
  background-color: $background;
  color: $color;
  
  @include user-select(none);
  padding: 10px 16px;
  text-decoration: none;
  line-height: 1;
  transition: .3s ease-in-out;
  @include media_desktop() {
    min-width: 176px;
    font-size: 18px;
    font-weight: bold;
  }

  &.link-btn {
    &::after {
      content: "\e902";
      display: inline-block;
      margin-left: 7px;
      font-family: 'kashimura-icon';
      vertical-align: baseline;
      transform: scale(0.5) translateY(3px);
    }
  }

  &.add-cart {
    font-size: 15px;
    font-weight: bold;
    @include media_desktop() {
      font-size: 18px;
    }
    &::after {
      display: none;
    }
    &::before {
      content: "\e903";
      display: inline-block;
      margin-right: 9px;
      color: white;
      font-family: 'kashimura-icon';
      font-size: 26px;
      font-weight: normal;
      vertical-align: sub;
      }
  }

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
      outline: none;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    // background-color: lighten($background, 3.5%) !important;
    background-color: lighten($background, 3.5%);
    color: $btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    // @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    @include opacity(.65);
    // @include box-shadow(none);
  }

  &.comingsoon {
    pointer-events: none;
  }

  // @include button-variant($color, $background, $border);
  // [converter] extracted a& to a.btn

  .ec-icon img {
    width: 1em;
    vertical-align: text-bottom;
  }
}

@mixin btn-default(){
  @include _btn($btn-default-color, $btn-default-bg, $btn-border)
}
@mixin btn-action(){
  @include _btn($btn-action-color, $btn-action-bg, $btn-border)
}
@mixin btn-cancel(){
  @include _btn($btn-cancel-color, $btn-primary-bg, $btn-border)
}
@mixin btn-primary(){
  @include _btn($btn-primary-color, $btn-primary-bg, $btn-border)
}
@mixin btn-reverse(){
  @include _btn($btn-primary-bg, $btn-primary-color, $btn-primary-bg);
  border: 1px solid $btn-primary-bg;
  border-radius: 5px;
  font-size: 14px;

  @include media_desktop() {
    min-width: inherit;
    font-size: 14px;
  }

  &:hover,
  &:focus {
    background-color: $btn-primary-bg;
    color: $btn-primary-color;
  }

}

@mixin blockBtn-default(){
  @include _btn($btn-default-color, $btn-default-bg, $btn-border);
  display: block;
  width: 100%;
  height:60px;
  line-height:60px;
  padding-top: 0;
  padding-bottom: 0;
}
@mixin blockBtn-action(){
  @include _btn($btn-action-color, $btn-action-bg, $btn-border);
  display: block;
  width: 100%;
  height:60px;
  line-height:60px;
  padding-top: 0;
  padding-bottom: 0;
}
@mixin blockBtn-cancel(){
  @include _btn($btn-cancel-color, $btn-primary-bg, $btn-border);
  display: block;
  width: 100%;
  height:60px;
  line-height:60px;
  padding-top: 0;
  padding-bottom: 0;
}
@mixin blockBtn-primary(){
  @include _btn($btn-primary-color, $btn-primary-bg, $btn-border);
  display: block;
  width: 100%;
  height:60px;
  line-height:60px;
  padding-top: 0;
  padding-bottom: 0;
}
@mixin blockBtn-reverse(){
  @include _btn($btn-primary-bg, $btn-primary-color, $btn-primary-bg);
  display: block;
  width: 100%;
  height:60px;
  line-height:60px;
  padding-top: 0;
  padding-bottom: 0;
  border: 1px solid $btn-primary-bg;
  border-radius: 5px;
  font-size: 14px;

  @include media_desktop() {
    min-width: inherit;
    font-size: 14px;
  }

  &:hover,
  &:focus {
    background-color: $btn-primary-bg;
    color: $btn-primary-color;
  }

}


@mixin linkBtn{
  &.disabled,
  fieldset[disabled] & {
    pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
  }
}
