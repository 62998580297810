@import "../mixins/media";
@import "../mixins/projects";

/*
ファームシェアリング

ファームシェアリング に関する Project コンポーネントを定義します。
*/
.ec-farmshareRole {
  width: 100%;
  max-width: 1130px;
  margin: 0 auto;
  padding: 40px 16px;

  section {
    + section {
      padding: 40px 0;
    }
  }

  .ec-farmshareHeader {
    margin-bottom: 40px;
    padding: 10px;
    background: transparent linear-gradient(276deg, #759859 0%, #cad878 54%, #759859 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    color: white;
    text-align: center;

    @include media_desktop {
      padding: 14px 20px 20px;
    }

    h1 {
      margin: 0;
      font-size: 13px;
      font-weight: bold;
      text-shadow: 1px 1px 1px #759859;

      @include media_desktop {
        font-size: 15px;
      }

      span {
        font-size: 46px;
        letter-spacing: 0;

        @include media_desktop {
          font-size: 53px;
        }
      }
    }
  }
  .catchcopy {
    margin-bottom: 40px;
    font-size: 20px;
    text-align: center;
    @include media_desktop {
      font-size: 30px;
    }
  }
  h3 {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    @include media_desktop {
      font-size: 24px;
    }
  }

  .ec-secHeading {
    width: fit-content;
    margin: 0 auto 40px;
    padding: 0 20px;
    background: url(../img/common/tape.svg) 50% 50% repeat-x;
  }

  figure {
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  ul {
    padding-left: 20px;
    @include media_desktop {
      padding-left: 40px;
    }
    li {
      margin-bottom: 10px;
      font-size: 16px;
      @include media_desktop {
        font-size: 20px;
      }
    }
  }

  .grid-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media_desktop {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 40px;
      justify-content: space-between;
    }

    > div {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding: 16px;
      border: 2px solid #97BC65;
      border-radius: 8px;
      overflow: hidden;

      @include media_desktop {
        width: calc(50% - 20px);
        padding: 32px;
      }


      &::before {
        position: absolute;
        top: -40px;
        left: -20px;
        z-index: 2;
        color: #97BC65;
        font-size: 220px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 700;
        line-height: 1;
        opacity: 0.3;
      }

      &:nth-child(1) {
        &::before {
          content: "A";
        }
      }
      &:nth-child(2) {
        &::before {
          content: "B";
        }
      }
      &:nth-child(3) {
        &::before {
          content: "C";
        }
      }
      &:nth-child(4) {
        &::before {
          content: "D";
        }
      }

      figure {
        position: relative;
        z-index: 1;
        margin: 0 0 40px;
        padding: 10px 0;
        border-bottom: 1px solid #ccc;
        img {
          display: block;
          max-width: 300px;
          margin: 0 auto;
        }
      }
      figcaption {
        margin-top: 10px;
        font-size: 20px;
        text-align: center;
      }

      .description {
        margin: 2rem 0 4rem;
      }
  
    }
  }

  .businessPlan {
    width: 100%;
    padding: 16px;
    border: 2px solid #8371C1;
    border-radius: 8px;
    background-color: #8371C111;
    overflow: hidden;

    @include media_desktop {
      padding: 32px;
    }

    .ec-price__price {
      font-size: 4rem;
    }
    .ec-price__tax {
      font-size: 3rem;
    }

    .ec-grid2 {
      flex-wrap: nowrap;
      gap: 40px;
      margin: 40px 0;
    }

    figure {
      margin: 0;
    }
    h3 {
      text-align: center;
    }
    h4 {
      color: #8371C1;
      font-size: 20px;
    }

    ul {
      li {
        font-size: 16px;
      }
    }

    .ec-farmshareRole__btn {
      max-width: 400px;
      margin: 0 auto;

      .ec-blockBtn--primary {
        background-color: #8371C1;
      }
    }
  }

  .ec-price {
    color: #DE5D50;
    font-size: 150%;
    &__price {
      font-size: 3rem;
    }
    &__unit {
      font-size: 2rem;
    }
    &__tax {
      font-size: 1.5rem;
    }
  }

}

.ec-bgcolorRole {
  background-color: $clrBg;
}