@import "../mixins/media";
@import "../mixins/projects";

/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/
.ec-cartRole {
  @include container;
  &::before{
    display: none;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  & &__progress {
    width: 100%;
    text-align: center;
  }
  & &__error {
    width: 100%;
    text-align: center;
    .ec-alert-warning {
      max-width: 80%;
      display: inline-block;
    }
  }
  & &__totalText {
    margin-bottom: 0;
    padding: 16px 0 6px;
    width: 100%;
    text-align: center;
    font-weight: normal;
    @include media_desktop {
      margin-bottom: 30px;
      padding: 0;
    }
  }
  & &__cart {
    margin: 0;
    width: 100%;
    @include media_desktop {
      // margin: 0 10%;
    }

  }
  & &__actions{
    text-align: right;
    width: 100%;
    @include media_desktop {
      width:  30%;
      // margin-right: 10%;
    }
  }
  & &__total{
    padding: 15px 0 30px ;
    font-weight: bold;
    font-size: 16px;
  }
  & &__totalAmount{
    margin-left: 30px;
    color: #de5d50;
    font-size: 16px;
    @include media_desktop {
      font-size: 24px;
    }
  }

  .ec-blockBtn--action {
    margin-bottom: 10px;
  }
}
form.ec-cartRole {
  padding: 0;
}

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品をを表示するテーブル枠です。

ex [カートページ　テーブル部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartTable

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/
.ec-cartTable{
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%;
  @include media_desktop {
    border-top: none;
  }
}


/*
カート商品表示枠（テーブルヘッダ）

カート内の商品を表示するテーブルのヘッダです。
スマホでは非表示となります。

ex [カートページ　カートテーブルヘッダ部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)


Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartHeader

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.3
*/
.ec-cartHeader{
  display: none;
  width: 100%;
  background: #F4F3F0;
  @include media_desktop {
    display: table-row;
  }
  & &__label{
    display: table-cell;
    padding: 16px;
    text-align: center;
    background: #F4F3F0;
    overflow-x: hidden;
    font-weight: bold;
  }
}
.ec-cartCompleteRole {
  @include container;
}
/*
カート内商品

カート内のアイテムを表示するテーブル行です。
スマホでは非表示となります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRow

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.4
*/

.ec-cartRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  list-style-type: none;
  @include media_desktop {
    display: table-row;
  }
  & &__delColumn {
    width: 14%;
    text-align: center;
    @include media_desktop {
      display: table-cell;
      width: 8.3333333%;
      border-bottom: 1px dotted #ccc;
      vertical-align: middle;
    }
    .ec-icon {
      img {
        width: 1.5em;
        height: 1.5em;
        @include media_desktop {
          width: 1em;
          height: 1em;
        }
      }
    }
  }
  & &__contentColumn {
    border-bottom: 1px dotted #ccc;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    width: 86%;
    @include media_desktop {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: auto;
    }
  }
  & &__img {
    display: block;
    padding-right: 10px;
    @include media_desktop {
      min-width: 80px;
      max-width: 100px;
      padding-right: 0;
    }
  }
  & &__summary {
    display: block;
    margin-left: 5px;
    font-size: 16px;
    font-weight: bold;
    @include media_desktop {
      width: auto;
      margin-left: 20px;
    }
    .ec-cartRow__name {
      margin-bottom: 5px;
    }
    .ec-cartRow__sutbtotalSP {
      display: none;
    }
  }
  & &__amountColumn {
    width: 86%;
    margin-left: auto;
    border-bottom: 1px dotted #ccc;
    text-align: left;
    @include media_desktop {
      display: table-cell;
      width: 16.66666667%;
      text-align: center;
    }

    .ec-cartRow__amount {
      display: none;
      margin-bottom: 10px;
      @include media_desktop {
        display: block;
      }
    }
    .ec-cartRow__amountSP {
      display: inline-block;
      padding: 10px 5px;
      font-size: 16px;
      @include media_desktop {
        display: none;
      }
    }

    .ec-cartRow__amountUpDown {
      display: inline-block;
      margin-left: 10px;
      @include media_desktop {
        display: block;
        margin: 0;
      }
    }

    .ec-cartRow__amountUpButton {
      margin: 0 2px;
      display: inline-block;
      // border: 2px solid #c9c9c9;
      border: 1px solid #b3b3b3;
      // border-radius: 50%;
      border-radius: 5px;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff;


      .ec-cartRow__amountUpButton__icon {
        .svg {
          display: block;
          margin-left: -0.4em;
          width: .8em;
          height: .8em;
          position: absolute;
          top: 28%;
          left: 50%;
          color: #888888;
          
          path {
            fill: currentColor;
          }
        }
      }
    }
    .ec-cartRow__amountDownButton {
      margin: 0 2px;
      display: inline-block;
      // border: 2px solid #c9c9c9;
      border: 1px solid #b3b3b3;
      // border-radius: 50%;
      border-radius: 5px;
      width: 30px;
      min-width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #fff;

      .ec-cartRow__amountDownButton__icon {
        .svg {
          display: block;
          margin-left: -0.4em;
          width: .8em;
          height: .8em;
          position: absolute;
          top: 28%;
          left: 50%;
          color: #888888;
          
          path {
            fill: currentColor;
          }
        }
      }
    }

    .ec-cartRow__amountDownButtonDisabled {
      @extend .ec-cartRow__amountDownButton;
      cursor: default;
    }
  }
  & &__subtotalColumn{
    display: none;
    border-bottom: 1px dotted #ccc;
    text-align: right;
    width: 16.66666667%;
    @include media_desktop {
      display: table-cell;
    }
  }
}

/*
カート内商品(商品が１の場合)

商品が１の場合はカート商品を減らす「-」ボタンの無効化状態になります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRowOnly

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.5
*/

.ec-cartRow{
  & &__amountColumn{
    .ec-cartRow__amountDownButtonDisabled {
      @extend .ec-cartRow__amountDownButton;
      cursor: default;
    }
  }
}

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartRole
  .ec-cartRole__cart
    +ec-alert-warning

Styleguide 15.1.6
*/

.ec-alert-warning {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: $clrRed;
  margin-bottom: 20px;


  & &__icon {
    display: inline-block;
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    vertical-align: top;
  }
  & &__text {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative;
  }
}




/*
アラート(空)

カートが空であることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-off3Grid
        .ec-off3Grid__cell
            +ec-alert-warningEnpty

Styleguide 15.1.7
*/
