
/*
wordpress

wordpress に関する Project コンポーネントを定義します。

Styleguide 24.1

*/
.wp-layoutRole {
    @include media_desktop {
        // max-width: 810px;
        margin: 0 auto;
    }
    header {
        margin-bottom: 20px;     
        @include media_desktop {
            // margin-bottom: 40px;
        }
        h1 {
            margin: 0 0 10px;
            padding: 0;
            font-size: 18px;
            @include media_desktop {
                font-size: 22px;
            }
        }
        figure {
            margin: 0 -20px 20px;
            padding: 0;
            @include media_desktop {
                margin: 0 0 20px;
            }
        }
        .category {
            font-size: 16px;
            font-weight: bold;
            @include media_desktop {
                font-size: 18px;
            }
        }

        &.ec-pageHeader {
            h1 {
                position: relative;
                z-index: 0;
                margin: 0 0 8px;
                padding: 8px 10px 10px;
                font-size: 18px;
                font-weight: bold;
                @include media_desktop {
                  margin: 10px 0 48px;
                  padding: 0 10px 6px;
                  font-size: 32px;
                  font-weight: bold;
                }
            }
        }
    }
    
    .picture_book {
        margin-bottom: 20px;
        padding: 0 20px;
        @include media_desktop {
            max-width: 530px;
            margin: 0 auto 40px;
            padding: 0;
        }
        .entry-content {
            font-size: 15px;
            letter-spacing: 0.05em;
            @include media_desktop {
                font-size: 18px;
            }
            p {
                margin-bottom: 1.5em;
            }
            dl {
                dt {
                    font-weight: bold;
                }
                dd {
                    margin-bottom: 1.5em;
                }
            }
            ul.calendar {
                display: flex;
                flex-wrap: nowrap;
                margin-top: 5px;
                padding: 0;
                list-style-type: none;
                li {
                    width: calc(100% / 12);
                    height: 0;
                    padding-bottom: calc(100% / 12 - 2px);
                    border: 1px solid $clrGold;
                    border-left: none;
                    font-size: 14px;
                    text-align: center;
                    line-height: 2;
                    @include media_desktop {
                        line-height: 3;
                    }

                    &:first-child {
                        border-left: 1px solid $clrGold;
                    }
                    &.harvest1 {
                        background-color: #E3F2D0;
                    }
                    &.harvest2 {
                        background-color: #FFECB9;
                    }
                    
                }
            }
            ul.legend {
                list-style-type: none;
                text-align: right;
                li {
                    display: inline-block;
                    margin-left: 1em;
                    font-size: 12px;
                    line-height: 1.333;

                    i {
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        margin-right: 5px;
                        border: 1px solid $clrGold;
                        vertical-align: middle;
                        line-height: 16px;
                    
                        &.harvest1 {
                            background-color: #E3F2D0;
                        }
                        &.harvest2 {
                            background-color: #FFECB9;
                        }
                    }
                }
            }

        }
    }

    .recipe {
        margin-bottom: 20px;
        padding: 0 20px;
        @include media_desktop {
            max-width: 1130px;
            margin: 0 auto 40px;
        }
        .entry-title {
            margin: 40px auto;
            font-size: 20px;
            text-align: center;
            @include media_desktop {
                font-size: 24px;
            }
        }
        .entry-content {
            font-size: 15px;
            letter-spacing: 0.05em;
            @include media_desktop {
                font-size: 18px;
            }
            p {
                margin-bottom: 1.5em;
            }
        }
        figure {
            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
            }
        }
        .ec-off4Grid {
            margin-top: 40px;
        }
    }

    .ec-shelfRole {
        padding: 0;
        h1 {
            position: relative;
            margin: 0 0 8px;
            padding: 8px 10px 10px;
            font-size: 16px;
            font-weight: bold;
            @include media_desktop {
                margin: 10px 0 20px;
                padding: 0 10px 6px;
                font-size: 22px;
                font-weight: bold;
            }
            span {
                position: relative;
                z-index: 1;
            }
            &::after {
                content: "";
                position: absolute;
                display: block;
                bottom: 0;
                left: 0;
                z-index: 0;
                width: 100%;
                height: 4px;
                background-color: $clrYellowishGreen;
                -webkit-mask-image: url(../img/common/tape2.svg);
                mask-image: url(../img/common/tape2.svg);
                -webkit-mask-size: 16px;
                mask-size: 16px;
                @include media_desktop {
                    height: 16px;
                }
            }            
        }

        .ec-shelfGrid {
            padding: 10px;
            @include media_desktop {
                padding: 0;
            }
            &__item {
                @include media_desktop {
                    width: 34%;
                    max-width: 280px;
                }
                figure {
                    height: auto;
                    margin: 0 0 20px;
                    .item-name {
                        text-align: left;
                    }
                }
            }
        }
    }
    .contents {
        position: relative;
        figure {
            margin: 20px auto;
            flex: 0;
        }
        .imageblock {
            margin: 20px auto;
            img {
                max-width: 100%;
            }
        }
        .description {
            flex: 1;
        }
        .process {
            counter-reset: process;
            .process_block {
                p:first-child:before {
                    counter-increment: process;
                    content: counter(process);
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    margin-right: 16px;
                    border: 1px solid #000;
                    border-radius: 100%;
                    font-size: 16px;
                    text-align: center;
                    line-height: 24px;
                }
                .imageblock {
                    margin-top: 16px;
                }
                + .process_block {
                    margin-top: 20px;
                }
            }
        }
        + .contents {
            margin-top: 40px;
        }
        p {
            + p {
                margin-top: 1.5em;
            }
        }
    }
    article {
        margin: 0;
        padding: 0 1rem;
        @include media_desktop {
            padding: 0;
        }
        h2 {
            margin-top: 0;
            padding-top: 10px;
            font-size: 26px;
        }
        a {
            color: #000000;
        }
        p {
            font-size: 16px;
        }
        p.farmer {
            margin-bottom: 20px;
            font-weight: bold;
        }
        p.more {
            position: absolute;
            bottom: 0;
            right: 0;
            text-align: right;
            a {
                color: #337AB7;
                text-decoration: underline;
            }
        }
    }
}

.archive, .single {

    .ec-shelfRole {

        &.newsletter {
            section {
                margin-bottom: 40px;
                
                p {
                    padding: 0 10px;
                }

                &.pickup {
                    figure.post-thumbnail {
                        max-width: 400px;
                        margin: 0 auto 20px;
                    }
                }

                .ec-blockBtn {
                    margin-top: 20px;
                }
            }

            .ec-pageHeader {
                h1 {
                    margin-bottom: 20px;
                    @include media_desktop {
                        margin-bottom: 34px;
                    }
                }
            }
        }

    }

}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    /* -webkit-box-pack: center;
    -ms-flex-pack: center; */
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    > * {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }
}
  