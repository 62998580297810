@import "../mixins/media";

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  @include container;
  padding: 0;

  @include media_desktop {
    .ec-grid2 .ec-grid2__cell {
      width: 45%;
      max-width: 490px;
    }
    .ec-grid2 .ec-grid2__cell:first-child {
      width: 55%;
      max-width: 600px;
    }
  }

  & &__img {
    margin-right: 0;
    margin-bottom: 20px;
    @include media_desktop {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  & &__profile {
    margin-left: 0;
    padding: 0 16px;
    @include media_desktop {
      margin-left: 20px;
      padding: 0;
    }
  }
  & &__title {
    .ec-headingTitle {
      font-size: 20px;
      font-weight: bold;
      @include media_desktop {
        font-size: 26px;
      }
    }
  }
  & &__tags {
    margin-top: 16px;
    padding: 0;
    // padding-bottom: 16px;
    // border-bottom: 1px dotted #ccc;
  }
  & &__tag {
    display: inline-block;
    padding: 2px 5px;
    list-style: none;
    font-size: 80%;
    color: #525263;
    border: solid 1px #525263;
    // border-radius: 3px;
    background-color: #fff;
  }
  & &__priceRegular {
    padding-top: 14px;
  }
  & &__priceRegularTax {
    margin-left: 5px;
    font-size: 10px;
  }
  & &__price {
    justify-content: flex-end;
    align-items: flex-end;
    color: #DE5D50;
    font-size: 28px;
    padding: 0;
    border-bottom: 0;
    text-align: right;
    @include media_desktop {
      padding: 10px 0;
      border-bottom: 3px solid $clrGold;
    }
  }
  & &__code {
    padding: 14px 0;
    border-bottom: 1px dotted #ccc;
  }
  & &__category {
    padding: 14px 0;
    border-bottom: 1px dotted #ccc;
    a {
      color: #33A8D0;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  & &__guide {
    padding: 20px 10px;
    border-bottom: 1px solid $clrGold;
    h3 {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: bold;
    }
    dl {
      list-style: none;
      padding: 0;
      margin: 0;
      line-height: 1.5;
    }
    dt {
      display: inline-block;
      padding: 0;
      margin: 0;
      font-weight: bold;
    }
    dd {
      display: inline;
      padding: 0;
      margin: 0;
      &:after {
        content: "\A";
        white-space: pre;
      }
    }
    p {
      margin-bottom: 20px;
    }
  }
  & &__actions {
    padding: 20px 10px 0;
    .ec-select {
      select {
        height: 40px;
        max-width: 100%;
        min-width: 100%;
        @include media_desktop {
          min-width: 350px;
          max-width: 350px;
        }
      }
    }
  }
  & &__btn {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 48px;
    @include media_desktop {
      // width: 60%;
      margin-bottom: 16px;
      padding: 0 10px;
      min-width: 350px;
    }
    .ec-blockBtn--action[disabled="disabled"] {
      @include _btn(white, $btn-cancel-bg, transparent);
      display: block;
      min-width: inherit;
      padding: 12px;
      font-size: 14px;
      font-weight: bold;
      
      @include media_desktop {
        font-size: 18px;
      }
    }
  
  }
  & &__description {
    margin-bottom: 16px;
    padding: 0 16px;
    @include media_desktop {
      padding: 0;
    }
    
    h3 {
      position: relative;
      margin-bottom: 12px;
      padding: 0 0 8px;
      font-size: 16px;
      @include media_desktop() {
        padding: 10px;
        font-size: 18px;
      }
       
      &::after {
        content: "";
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: url(../img/common/tape.svg) center bottom repeat-x;
      }
    }

    p {
      padding: 0;
      font-size: 15px;
      @include media_desktop() {
        padding: 0 10px;
        font-size: 18px;
      }
    }
  }

  .ec-sliderItemRole {
    @include media_desktop {
      padding: 0;
    }
    .item_visual {
      width: 100%;
      @include media_desktop {
        max-width: 500px;
        width: calc(84% - 4px);
        float: right;
      }
    }
    .item_nav {
      max-width: 100px;
      width: calc(16% + 4px);
      float: left;
      padding-right: 8px;
      box-sizing: border-box;
      .slideThumb {
        width: 100%;
        margin-bottom: 0;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .allergen {
    margin: 10px 0;
    ul {
      display: flex;
      justify-content: left;
      list-style-type: none;
      padding: 0;
      li {
        width: 15%;
        margin-right: 5px;
        @include media_desktop {
          width: 30%;
        }
      }
    }
  }
}

#RelatedProduct-product_area {
  margin-top: 40px;
  padding: 0 16px;
  @include media_desktop {
    padding: 0;
  }
  
  h3 {
    position: relative;
    margin-bottom: 20px;
    padding: 0 0 8px;
    font-size: 16px;
    @include media_desktop() {
      padding: 10px;
      font-size: 18px;
    }
     
    &::after {
      content: "";
      position: absolute;
      display: block;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: url(../img/common/tape.svg) center bottom repeat-x;
    }
  }

  p {
    padding: 0;
    font-size: 15px;
    @include media_desktop() {
      padding: 0 10px;
      font-size: 18px;
    }
  }
}


.ec-cartBtn__fixed {
  display: none;
  position: fixed;
  bottom: 60px;
  right: 0;
  z-index: 100;
  width: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 10px #999;
  transition: all 0.5s;

  @include media_desktop {
    bottom: 80px;
    max-width: 320px;
  }

  &.visibled {
    display: block;
  }

  h2.ec-headingTitle {
    display: none;
    font-size: 20px;
    @include media_desktop {
      display: block;
    }
  }

  .ec-productRole__price {
    display: none;
    @include media_desktop {
      display: block;
      margin-bottom: 20px;
    }
  }

  .kokokara_select_cart_btn_area {
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .ec-productRole & {
    .ec-productRole__btn {
      min-width: inherit;
      margin: 0;
    }
  }
}
