
/*
パンくずリスト

パンくずリスト に関する Project コンポーネントを定義します。

Markup:
include /assets/tmpl/elements/23.1.breadcrump.pug
+ec-pagerRole

Styleguide 23.1

*/
.ec-breadcrumpRole{
    margin-bottom: 20px;
    padding: 0 16px;
    @include media_desktop {
        margin-bottom: 32px;
        padding: 0;
    }
    ul {
        padding: 0;
        list-style-type: none;
        li {
            display: inline-block;
            &:after {
                content: '　/　';
                display: inline-block;
            }
        }
        li:last-child {
            &:after {
                content: '';
            }
        }
    }
}
