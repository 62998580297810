@import "../mixins/media";
/*
トップページ

トップページ スライド部に関する Project コンポーネントを定義します。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.1.slider.pug
+ec-sliderRole

Styleguide 12.1
*/
.ec-sliderRole{
  @include container;
  padding: 0;
  @include media_desktop() {
    max-width: 1290px;
  }
  // margin-bottom: 24px;
  ul{
    padding: 0;
    list-style: none;
  }


}
.ec-sliderItemRole{
  @include container;
  margin-bottom: 24px;
  ul{
    padding: 0;
    list-style: none;
  }
  .item_nav {
    display: none;
    @include media_desktop {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 0;
    }

  }
  .slideThumb{
    margin-bottom: 25px;
    width: 33%;
    opacity: .8;
    cursor: pointer;

    &:focus {
      outline: none;
    }
    &:hover {
      opacity: 1;
    }
    img {
      width: 80%;
    }
  }

}

.slick-slide {
  &:focus {
    outline: none;
  }
}


.slick-dots {
  position: absolute;
  bottom: -45px;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;

  li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
  
    cursor: pointer;
  
    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;

      &::before {
        content: " ";
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 12px;
        height: 12px;
        text-align: center;
        opacity: .25;
        background-color: black;
        border-radius: 50%;
      }

      &:hover,
      &:focus {
        outline: none;
        &::before {
          opacity: 1;
        }
      }

      &.thumbnail {
        img {
          width: 0;
          height: 0;          
        }
      }
    }

    &.slick-active {
      button {
        &::before {
          opacity: .75;
          background-color: black;          
        }
      }
    }

  }

}
