/*
見出し

トップページで使用されている見出しのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.4
*/

/*
横並び見出し

横並びの見出しです。

Markup:
.ec-secHeading
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.1
*/
.ec-secHeading {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  // color: black;
  text-align: center;
  @include media_desktop() {
    margin-bottom: 32px;
  }
  & &__en {
    position: relative;
    display: block;
    z-index: 1;
    margin-bottom: 10px;
    font-size: 28px;
    font-family: 'Amatic SC', sans-serif;
    font-weight: bold;
    line-height: 1.1;
    @include media_desktop() {
      font-size: 34px;
    }
  }
  & &__line {
    position: absolute;
    display: block;
    width: 160px;
    height: 15px;
    bottom: 40%;
    left: 50%;
    z-index: 0;
    transform: translateX(-50%);
    font-size: 0;
    .orange {
      path {
        fill: $clrTitle;
      }
    }
  }
  & &__ja {
    position: relative;
    display: block;
    z-index: 1;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.1;
  }
}

/*
縦並び見出し

縦並びの見出しです。

Markup:
.ec-secHeading--tandem
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.2
*/

.ec-secHeading--tandem {
  margin-bottom: 15px;
  color: black;
  text-align: center;
  & .ec-secHeading__en{
    display: block;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .2em;
  }
  & .ec-secHeading__line{
    display: block;
    margin: 13px auto;
    width: 20px;
    height: 1px;
    background: black;
  }
  & .ec-secHeading__ja{
    display: block;
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: .15em;
    // vertical-align: 2px;
  }
}
