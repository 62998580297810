@import "../mixins/media";
@import "../mixins/projects";
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footerRole{
  // border-top: 1px solid #7d7d7d;
  margin-top: 30px;
  background: $clrBg;
  box-sizing: border-box;

  @include media_desktop(){
    padding-top: 40px;
    margin-top: 100px;
  }
  & &__inner{
    @include media_desktop {
      @include container;
    }
  }


  & &__navSP {
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    // background: url(../img/common/footer_nav_bg.png) center center no-repeat;
    background-color: rgba(255, 255, 255, 0.44);
    background-size: 100% auto;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);

    @include media_desktop {
      display: none;
    }
    
  }

  .ec-footerNav__item {
    width: 20%;

    .ec-headerNavSP,
    a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      top: inherit;
      left: inherit;
      margin: 0;
      padding: 12px 0;
      border-radius: 0;
      background-color: transparent;
      color: #000;
      font-size: 0;
      text-align: center;
      text-decoration: none;
      cursor: pointer;

      &:focus,
      &:active {
        text-decoration: none;
      }

      i {
        font-size: 18px;
      }
      span {
        display: block;
        font-size: 9px;
      }
    }
        
  }

}

/*
フッターナビ

フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerNav

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerNavi {
  padding: 0;
  // color: white;
  list-style: none;
  text-align: center;

  @include media_desktop {
    display: flex;
    flex-wrap: nowrap;
    // justify-content: center;
    justify-content: space-evenly;
  }

  & &__link {
    display: block;
    border-bottom: 1px solid white;

    @include media_desktop {
      display: inline-block;
      // width: calc(100% / 6);
      border-bottom: none;
    }

    a {
      display: block;
      // border-bottom: 1px solid #7d7d7d;
      padding: 16px 0;
      color: inherit;
      font-size: 15px;
      line-height: 1.6;
      text-decoration: none;

      @include media_desktop {
        display: inline-block;
        border-bottom: none;
        margin: 0 10px;
        padding: 0;
        // text-decoration: underline;
      }
    }
    &:hover {
      a {
        opacity: .8;
        text-decoration: none;
      }

    }

  }
}

/*
フッタータイトル

フッタープロジェクトで使用するタイトル用のコンポーネントです。

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerTitle

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerTitle {
  padding: 20px 0 80px;
  text-align: center;
  // color: white;

  @include media_desktop {
    padding: 50px 0 80px;
  }

  & &__logo {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    @include reset_link();

    a{
      font-size: 22px;
      color: inherit;
      @include media_desktop {
        font-size: 24px;
      }

    }

    &:hover {
      a {
        opacity: .8;
        text-decoration: none;
      }
    }
  }
  & &__copyright {
    font-size: 10px;

    @include media_desktop {
      font-size: 12px;
    }
  }
}
