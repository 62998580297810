@import "../mixins/media";

/*
標準セクション

通常のコンテナブロックです。

ex [商品詳細ページ　コンテナ](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-roleRole

Styleguide 11.1
*/
.ec-role{
  @include container;
}


.ec-conceptRole{
  width: 100%;
  box-sizing: border-box;
  @include clearfix;
  background-color: $clrBg;
  font-size: 18px;
  line-height: 1.6;
  -webkit-text-size-adjust: 100%;
}

/*
マイページセクション

マイページ専用のコンテナブロックです。

ex [マイページ　コンテナ](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-mypageRole

Styleguide 11.1.2
*/
.ec-mypageRole{
  // @include mypageContainer;
  @include container;

  .ec-pageHeader h1{
    @include media_desktop {
      margin: 10px 0 48px;
      padding: 8px 0 18px;
    }
  }

}


.ec-otherRole{
  @include container;
  max-width: 850px;
  padding: 0 20px;

  > figure {
    margin: 0 auto 20px;
    @include media_desktop {
      margin: 0 auto 30px;
    }
  }

  section {
    margin: 30px auto;

    &#about {
      figure {
        margin: 0 auto 20px;
        @include media_desktop {
          margin: 0 auto 30px;
        }    
      }
    }
    p {
      margin: 1.5em 0;
    }
  }

  > * {
    font-size: 14px;
    @include media_desktop {
      font-size: 18px;
    }
  }

  * + h2 {
    margin-top: 32px;
  }

  [class^="ec-blockBtn"] {
    margin-top: 30px;
    @include media_desktop {
      margin-top: 60px;
    }
  }

  .ec-grid2 {
    align-items: center;

    figure {
      text-align: center;

      img {
        width: auto;
      }
    }
  }

}

.ec-sharingRole {
  background-color: #97BC6533;
}