@import "../mixins/media";
@import "../mixins/clearfix";

/*
カテゴリ（アイテム3列）

トップページで使用されているカテゴリのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.7.1
*/

.ec-categoryRole {
  padding: 30px 20px;
  color: $clrDarkGray;
  background: $clrBg;

  @include media_desktop {
    padding: 40px 0;
  }

  .ec-secHeading {
    margin-top: 0;
    &__line {
      path {
        fill: #edc895;
      }
    }
  }

  & &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media_desktop {
      flex-wrap: nowrap;
    }

  }
  & &__listItem {
    width: 49%;
    width: calc(50% - 5px);
    height: auto;
    margin-bottom: 10px;

    @include media_desktop {
      width: 160px;
      margin-bottom: 20px;

      &:not(:last-of-type){
        margin-right: 26px;
      }
    }

    a {
      display: block;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      color: #ffffff;
      text-align: center;

      .category {
        position: absolute;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);
        width: 100%;

        span {
          display: block;
          font-weight: bold;

          &.category__name {
            font-size: 18px;
          }
          &.category__name_en {
            font-size: 12px;
            font-family: Arial, Helvetica, sans-serif;
          }
        }
      }

      &::before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.2);
        transition: all 0.5s ease;
      }

      &:hover {
        &::before {
          background-color: rgba($color: #000000, $alpha: 0.05);
        }
      }
    }

  }

}
