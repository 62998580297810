@import "../mixins/media";

/*
アイキャッチ

トップページ アイキャッチ部に関する Project コンポーネントを定義します。

ex [トップページスライダー直下 アイキャッチ部](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.9.service.pug
+ec-serviceRole

Styleguide 12.9
*/
.ec-serviceRole {
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  @include media_desktop {
    // flex-wrap: nowrap;
    width: 50%;
    margin: 0 auto;
    padding: 40px 0;
  }

  & &__image {
    display: block;
    width: 100%;
    height: 100%;
    padding: 30px 0;
    text-align: center;

    @include media_desktop {
      padding: 0;
      &:not(:last-of-type){
        margin-bottom: 30px;
      }
    }

    img {
      display: block;
      margin-bottom: 20px;
      border: none;
      @include media_desktop {
        margin-bottom: 15px;
      }
    }

    .ec-inlineBtn,
    .ec-inlineBtn--primary {
      font-size: 12px;
      font-weight: normal;
    }
  }

  &.ec-page {
    @include media_desktop {
      width: auto;
      padding: 0;
    }
  }
  & &__cell {
    max-width: 830px;
    margin: 0 auto;
    padding: 0 10px;

    @include media_desktop {
      // padding: 0;
    }

    .ec-serviceHeader {
      margin-bottom: 40px;
      padding: 10px;
      background: transparent linear-gradient(276deg, #FF9D19 0%, #FFCC58 54%, #FF9D19 100%) 0% 0% no-repeat padding-box;
      border-radius: 10px;
      color: white;
      text-align: center;
      @include media_desktop {
        padding: 14px 20px 20px;
      }

      h1 {
        margin: 0;
        font-size: 13px;
        font-weight: bold;
        @include media_desktop {
          font-size: 15px;
        }
        span {
          font-size: 46px;
          letter-spacing: 0;
          text-shadow: 1px 1px 1px #E6941F;
          @include media_desktop {
            font-size: 53px;
          }
        }
      }
    }
    .ec-grid3__cell2 {
      margin-bottom: 40px;
      padding: 0 10px;
      p {
        @include media_desktop {
          margin-bottom: 0;
          padding: 0 30px 0 0;
        }
      }
    }

    picture {
      display: block;
      margin-bottom: 40px;
      text-align: center;
    }

    p {
      font-size: 15px;
      line-height: 1.6;
      @include media_desktop {
        font-size: 18px;
      }
    }

    .toggle-block {
      margin: 0 10px 20px;
      border: 2px solid $clrOrange;
      border-radius: 10px;
      @include media_desktop {
        margin: 0 0 20px;
      }
      h2 {
        margin: 0;
        color: $clrOrange;
        font-size: 0;

        label {
          position: relative;
          display: block;
          width: 100%;
          margin: 0;
          padding: 14px 20px;
          font-size: 15px;
          cursor: pointer;
          @include media_desktop {
            padding: 16px 30px;
            font-size: 18px;
          }

          i {
            position: absolute;
            top: calc(50% - 0.5em);
            right: 20px;
            transform: rotate(180deg);
            transition: .3s all ease-in-out;
            @include media_desktop {
              right: 30px;
            }
          }

        }
      }

      .toggle-area {
        overflow: hidden;
        box-sizing: border-box;
        padding: 0 20px;
        @include media_desktop {
          padding: 0 30px;
        }
      }

      p {
        margin: 0;
        line-height: 0;
        opacity: 0;
        transition: margin-bottom .3s ease-out, line-height .3s ease-out, opacity .3s ease-out,;
      }

      input.hidden:checked ~ .toggle-area p {
        margin-bottom: 28px;
        line-height: 1.6;
        opacity: 1;
      }

      input.hidden:checked + h2 i {
        transform: rotate(0);
      }

    }


    .tab-block {
      margin-top: 20px;
      padding: 0 10px;
      @include media_desktop {
        padding: 0;
      }

      .tab-area {
        display: flex;
        justify-content: space-between;

        .tab {
          position: relative;
          width: 49%;
          width: calc(50% - 7.5px);
          // box-sizing: border-box;
          border-radius: 10px 10px 0 0;
          background-color: white;
          overflow: hidden;
          color: $clrOrange;
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
          text-align: center;
          transition: all .5s ease-out;
          @include media_desktop {
            width: calc(50% - 15px);
            font-size: 18px;
            line-height: 1.2;
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px 10px 0 0;
            background: transparent linear-gradient(276deg, #FF9D19 0%, #FFCC58 54%, #FF9D19 100%) 0% 0% no-repeat padding-box;
            opacity: 0;
            transition: all .5s ease-out;
          }

          label {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 10px;
            border: 2px solid $clrOrange;
            border-bottom: none;
            border-radius: 10px 10px 0 0;
            cursor: pointer;
            transition: border-color 1s ease-out;
            @include media_desktop {
              padding: 24px 10px;
            }
          }
        }
 
      }
      
      #tab1:checked ~ .tab-area .tab {
        &#tab1__tab {
          color: white;
          &::before {
            opacity: 1;
          }
          label {
            border-color: transparent;
          }
        }
        &:hover {
          color: white;
          &::before {
            opacity: 1;
          }
          label {
            border-color: transparent;
          }
        }

      }

      #tab2:checked ~ .tab-area .tab {
        &#tab2__tab {
          color: white;
          &::before {
            opacity: 1;
          }
          label {
            border-color: transparent;
          }
        }
        &:hover {
          color: white;
          &::before {
            opacity: 1;
          }
          label {
            border-color: transparent;
          }
        }

      }

      .tab-contents {
        position: relative;
        padding: 20px;
        border: 2px solid $clrOrange;
        background-color: white;
        overflow: hidden;
        @include media_desktop {
          padding: 30px;
        }

        > div {
          position: absolute;
          top: 20px;
          width: calc(100% - 60px);
          z-index: 0;
          opacity: 0;
          transition: z-index .3s ease-out, opacity .3s ease-out;
          @include media_desktop {
            top: 30px;
          }

          h2 {
            margin-top: 0;
            margin-bottom: 30px;
            color: $clrUIIcon;
            font-size: 18px;
            font-weight: bold;
            line-height: 1.6;
            letter-spacing: 0.02em;
            @include media_desktop {
              font-size: 20px;
            }

            &.underline-green {
              position: relative;
              padding-bottom: 20px;

              &::after {
                content: "";
                position: absolute;
                display: block;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 4px;
                background-color: $clrYellowishGreen;
                -webkit-mask-image: url(../img/common/tape.svg);
                mask-image: url(../img/common/tape.svg);          
              }
            }
          }
          h3 {
            margin-top: 60px;
            margin-bottom: 16px;
            color: $clrYellowishGreen;
            font-size: 18px;
            font-weight: bold;
            line-height: 1.6;
            letter-spacing: 0.02em;
            @include media_desktop {
              font-size: 20px;
            }

            &:first-of-type {
              margin-top: 0;
            }

          }

          .link-btn {
            @include media_desktop {
              max-width: 400px;
              margin: 30px auto 0;
            }
          }

          .circle {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 220px;
            height: 220px;
            margin: 0 auto;
            border: 2px solid $clrYellowishGreen;
            border-radius: 50%;
            text-align: center;

            h3 {
              margin-bottom: 10px;
              font-size: 18px;
              line-height: 1.45;
            }
            p {
              margin: 0;
              font-size: 14px;
            }
          }

          .ec-grid3 {
            justify-content: center;
            margin-bottom: 60px;
            &__cell {
              padding: 0 25px;
              @include media_desktop {
                width: 270px;
                max-width: 270px;
              }
              &:first-child {
                margin-bottom: 20px;
                @include media_desktop {
                  margin-bottom: 0;
                }
              }
            }
          }


        }
      }

      #tab1:checked ~ .tab-contents > div#tab1__contents,
      #tab2:checked ~ .tab-contents > div#tab2__contents {
        position: relative;
        top: inherit;
        z-index: 1;
        opacity: 1;
        width: 100%;
      }





    }

  }

  input.hidden {
    display: none;
  }

}

.question {
  display: block;
  margin-bottom: 30px;
  padding: 16px 0;
  @include media_desktop {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
    padding: 20px 0;
    border-bottom: 1px solid #F7F3EC;
  }

  dt {
    margin-bottom: 5px;
    padding-left: 10px;
    font-size: 15px;
    font-weight: bold;
    @include media_desktop {
      width: 58%;
      margin-bottom: 0;
      padding-left: 0;
      font-size: 18px;
    }
  }
  dd {
    @include media_desktop {
      width: 42%;
    }
  }
  input[type="checkbox"] {
    display: none;
  }
  label {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 50px;
    overflow: hidden;
    margin: 0;
    border-radius: 25px;
    background-color: #F7F3EC;
    font-weight: normal;

    * {
      transition: all 0.3s;
    }

    span {
      position: relative;
      z-index: 1;
      width: 50%;
      font-size: 16px;
      text-align: center;
      &.yes {
        color: #EB9B8C;
      }
      &.no {
        color: #fff;
        font-weight: bold;
      }
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      width: 50%;
      height: 100%;
      background-color: #888888;
      transition: all 0.3s;
    }
  }

  input:checked {
    + label {
      span {
        &.yes {
          color: #fff;
          font-weight: bold;
        }
        &.no {
          color: #888;
          font-weight: normal;
        }
      }

      &::before {
        left: 0;
        background-color: #EB9B8C;
      }
    }
  }

  + .ec-off3Grid {
    button {
      font-size: 15px;
      font-weight: bold;
      @include media_desktop {
        font-size: 18px;
      }
      &::after {
        content: "\e902";
        display: inline-block;
        margin-left: 10px;
        font-family: 'kashimura-icon';
        font-size: 12px;
        font-weight: normal;
        @include media_desktop {
          font-size: 14px;
        }
      }
    }
  }

}

.result__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;

  & &Item1 {
    width: 100%;
    margin-bottom: 20px;
  }
  & &Item2, & &Item4 {
    width: 100%;
    margin-bottom: 30px;
    @include media_desktop {
      width: 50%;
      padding: 0 10px;
    }
  }
  & &Item3 {
    width: 100%;
    margin-bottom: 30px;
    @include media_desktop {
      width: 33%;
      padding: 0 10px;
      margin-bottom: 20px;
    }
  }

  figure {
    margin: 0;
    padding: 0;
  }
}

