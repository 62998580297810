@import "../mixins/media";

/*
アイキャッチ

トップページ アイキャッチ部に関する Project コンポーネントを定義します。

ex [トップページスライダー直下 アイキャッチ部](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.2.eyecatch.pug
+ec-eyecatchRole

Styleguide 12.2
*/
.ec-eyecatchRole {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include container;
  // margin-bottom: 40px;
  padding: 30px 20px;

  @include media_desktop {
    display: block;
    // flex-wrap: nowrap;
    padding: 40px 30px;
  }

  & &__image {
    display: block;
    order: 3;
    // margin-bottom: 40px;
    // width: 100%;
    // height: 100%;
    width: 108px;
    margin: 0;

    @include media_desktop {
      // order: 2;
      float: right;
      width: 280px;
      padding-right: 10px;
    }

    .ec-sharingRole & {
      @include media_desktop {
        // order: 2;
        float: left;
        width: 280px;
        padding-right: 40px;
      }
    }
  }

  & &__intro {
    color: black;

    @include media_desktop {
      padding-right: 5%;
      order: 1;
    }
  }

  & &__introEnTitle {
    margin-bottom: .8em;
    font-size: 16px;
    font-weight: normal;

    @include media_desktop {
      margin-top: 45px;
    }
  }

  & &__introTitle {
    margin-top: 0;
    margin-bottom: .8em;
    color: $clrBrown;
    font-size: 24px;
    font-weight: bold;

    @include media_desktop {
      float: left;
      width: calc(100% - 320px);
      margin-top: 20px;
      margin-bottom: 1em;
      font-size: 26px;
    }
  }

  & &__introDescription {
    order: 2;
    width: calc(100% - 118px);
    margin-bottom: 20px;
    font-size: 16px;

    @include media_desktop {
      float: left;
      width: 55%;
      max-width: 570px;
      margin-bottom: 30px;
      line-height: 2;
    }

    .ec-sharingRole & {
      @include media_desktop {
        width: calc(100% - 320px);
        max-width: 100%;
        margin-bottom: 30px;
      }
    }
  }

  .ec-blockBtn {
    order: 3;
    clear: left;
    max-width: 176px;
    height: auto;
    margin: 0 auto;
    padding: 10px 16px;
    background-color: #f1b066;
    color: #fff;
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    text-decoration: none;
  }

}