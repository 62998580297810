@import "../mixins/media";
@import "../mixins/projects";
/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2

*/
.ec-shelfRole{
  @include container;
}

/*
商品一覧グリッド

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGrid

Styleguide 13.2.1

*/
.ec-shelfGrid{
  @include reset_link;
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  align-items: flex-start;

  @include media_desktop {
    margin-left: -15px;
    margin-right: -15px;
  }
  & &__item{
    margin-bottom: 40px;
    width: 50%;
    display: flex;
    flex-direction: column;
    &-image {
      // height: 150px;
      margin-bottom: 10px;
      text-align: center;
      @include media_desktop() {
        // height: 250px;
      }
    }
    img{
      width: auto;
      max-height: 100%;
      // object-fit: none;
      transition: .3s ease-in-out;
      
      &:hover {
        opacity: 0.5;
      }
    }
    @include media_desktop(){
      padding: 0 15px;
      width: 25%;
    }

    .item-name {
      font-size: 18px;
      font-weight: bold;
    }

    .item-price {
      margin-bottom: 20px;
      font-size: 20px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;

      span {
        font-size: 14px;
        font-weight: normal;
      }
    }

    .ec-productRole__btn {
      margin-top: auto;
      // margin-bottom: 15px;

      .ec-blockBtn--shelf {
        @include _btn(white, $clrYellowishGreen, transparent);
        display: block;
        min-width: inherit;
        padding: 12px;
        font-size: 14px;
        font-weight: bold;
        
        @include media_desktop {
          font-size: 18px;
        }
        
        &.link-btn::after {
          transform: scale(0.675) translateY(2px);
        }

      }

      .ec-blockBtn--cancel {
        @include _btn(white, $btn-cancel-bg, transparent);
        display: block;
        min-width: inherit;
        padding: 12px;
        font-size: 14px;
        font-weight: bold;
        
        @include media_desktop {
          font-size: 18px;
        }
      }
    }

    .description_list {
      margin-top: 5px;
      font-size: 14px;
      h3 {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  & &__item:nth-child(odd){
    padding-right: 8px;
    @include media_desktop(){
      padding: 0 15px;
    }
  }
  & &__item:nth-child(even){
    padding-left: 8px;
    @include media_desktop(){
      padding: 0 15px;
    }
  }
  & &__title {
    margin-bottom: 7px;
  }
  & &__plice {
    font-weight: bold;
  }
}

/*
13.2.2 商品一覧グリッド（中央寄せ）

商品一覧 で使用するグリッドコンポーネントです。

SP版２列、PC版４列の特殊グリッドを構成します。
商品のあまりはセンタリングされ、中央に表示されます。

Markup:
include /assets/tmpl/elements/13.2.shelf.pug
+b.ec-shelfRole
  +ec-shelfGridCenter

Styleguide 13.2.2

*/
.ec-shelfGridCenter{
  @include reset_link;
  display: flex;
  margin-left: 0;
  margin-right: 0;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: center;

  @include media_desktop {
    margin-left: -16px;
    margin-right: -16px;
  }
  & &__item{
    margin-bottom: 36px;
    width: 50%;
    &-image {
      // height: 150px;
      margin-bottom: 10px;
      text-align: center;
      @include media_desktop() {
        // height: 250px;
      }
    }
    img{
      width: auto;
      max-height: 100%;
    }
    @include media_desktop(){
      padding: 0 15px;
      width: 25%;
    }

    .ec-productRole__btn {
      margin-top: auto;
      padding-top: 1em;
    }
  }
  & &__item:nth-child(odd){
    padding-right: 8px;
    @include media_desktop(){
      padding: 0 15px;
    }
  }
  & &__item:nth-child(even){
    padding-left: 8px;
    @include media_desktop(){
      padding: 0 15px;
    }
  }
  & &__title {
    margin-bottom: 7px;
  }
  & &__plice {
    font-weight: bold;
  }
}
